'use client'

import { BUTTON_VARIANT } from 'fukku/Button/Button.types'
import { useTrapFocus } from 'hooks/useTrapFocus/useTrapFocus'
import { IconDropdownNextSmall } from 'icons/components/IconDropdownNextSmall'
import { IconDropdownPrevSmall } from 'icons/components/IconDropdownPrevSmall'
import { type RefObject, useEffect, useRef, useState } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { ButtonIconTransparent } from '../../Button/Icon/Transparent'
import { CommBanner, type CommBannerProps } from '../CommBanner/CommBanner'
import { COMM_BANNER_TYPE } from '../types'
import { isValidColor } from '../utils'

import styles from './CommsBannerCarousel.module.scss'

const TRANSITION_MILLISECONDS = 300
export interface CommsBannerCarouselProps {
	id: string
	comms: CommBannerProps[]
	previousButtonLabel: string
	nextButtonLabel: string
	className?: string
}

export function CommsBannerCarousel({
	id,
	comms,
	previousButtonLabel,
	nextButtonLabel,
	className,
}: CommsBannerCarouselProps) {
	const carouselRef = useRef(null)
	const listRef = useRef<HTMLElement | null>(null)

	const [carouselPosition, setCarouselPosition] = useState(0)
	const [bannerWidth, setBannerWidth] = useState<number | null>(null)
	const [isTransitionOngoing, setIsTransitionOngoing] = useState(false)
	const isAtStart = carouselPosition === 0
	const isAtEnd = carouselPosition === comms.length - 1
	const currentComm = comms[carouselPosition]
	const isInverse = Boolean(
		isValidColor(currentComm.bgColorType, currentComm.bgColorCode) &&
			currentComm.inverse
	)
	const showInfoTooltip = Boolean(
		currentComm.type === COMM_BANNER_TYPE.PROMO && currentComm.infoText
	)

	useTrapFocus(!isAtStart && !isAtEnd ? carouselRef.current : null)

	useEffect(() => {
		const updateBannerWidth = () => {
			setBannerWidth(listRef.current?.getBoundingClientRect()?.width ?? null)
		}

		if (listRef.current) {
			updateBannerWidth()
			window.addEventListener('resize', updateBannerWidth)
		}

		return () => {
			listRef.current && window.removeEventListener('resize', updateBannerWidth)
		}
	}, [listRef.current])

	useEffect(() => {
		if (isTransitionOngoing) {
			setTimeout(() => {
				setIsTransitionOngoing(false)
			}, TRANSITION_MILLISECONDS)
		}
	}, [carouselPosition])

	const handleClickPreviousButton = () => {
		if (!isAtStart) {
			setIsTransitionOngoing(true)
			setCarouselPosition((value) => value - 1)
		}
	}

	const handleClickNextButton = () => {
		if (!isAtEnd) {
			setIsTransitionOngoing(true)
			setCarouselPosition((value) => value + 1)
		}
	}

	if (comms.length === 1) {
		return <CommBanner {...comms[0]} className={className} />
	}

	return (
		<div className={cx(styles.carouselWrapper, className)} ref={carouselRef}>
			<ButtonIconTransparent
				variant={isInverse ? BUTTON_VARIANT.INVERSE : BUTTON_VARIANT.DEFAULT}
				data-testid={`commsBanner.${id}.previous.button`}
				onClick={handleClickPreviousButton}
				className={cx(
					styles.scrollButton,
					applyStylesIf(isInverse, styles.inverse),
					styles.prevScrollButton,
					applyStylesIf(showInfoTooltip, styles.fitHeight)
				)}
				disabled={isAtStart}
			>
				<IconDropdownPrevSmall alt={previousButtonLabel} />
			</ButtonIconTransparent>
			<ul
				ref={listRef as RefObject<HTMLUListElement>}
				className={styles.bannerList}
				data-testid={`commsBanner.${id}.container`}
				id={id}
			>
				{comms.map((props: CommBannerProps, index) => (
					<li
						key={props.id}
						className={styles.bannerListItem}
						style={{
							height: index === carouselPosition ? 'auto' : '1px',
							left: `${-carouselPosition * (bannerWidth ?? 0)}px`,
							transition: isTransitionOngoing
								? `left ${TRANSITION_MILLISECONDS}ms ease-out`
								: 'none',
						}}
					>
						<CommBanner
							{...props}
							isHiddenInCarousel={index !== carouselPosition}
							infoIconButtonCarouselClassName={styles.infoIconButtonCarousel}
							closable={false}
							hasArrows
						/>
					</li>
				))}
			</ul>
			<ButtonIconTransparent
				data-testid={`commsBanner.${id}.next.button`}
				disabled={isAtEnd}
				onClick={handleClickNextButton}
				variant={isInverse ? BUTTON_VARIANT.INVERSE : BUTTON_VARIANT.DEFAULT}
				className={cx(
					styles.scrollButton,
					applyStylesIf(isInverse, styles.inverse),
					styles.nextScrollButton,
					applyStylesIf(showInfoTooltip, styles.fitHeight)
				)}
			>
				<IconDropdownNextSmall alt={nextButtonLabel} />
			</ButtonIconTransparent>
		</div>
	)
}

export default CommsBannerCarousel
