import { type RefObject, useLayoutEffect, useState } from 'react'

export function useTextOverflow(elementRef: RefObject<Element>) {
	const [isTextOverflowing, setIsTextOverflowing] = useState(false)

	useLayoutEffect(() => {
		const element = elementRef.current

		const handleTextOverflow = () => {
			if (
				element &&
				!isTextOverflowing &&
				element.scrollWidth > element?.clientWidth
			) {
				setIsTextOverflowing(true)
			}
		}

		handleTextOverflow()

		window.addEventListener('resize', handleTextOverflow)

		return () => {
			window.removeEventListener('resize', handleTextOverflow)
		}
	}, [elementRef.current])

	return isTextOverflowing
}
